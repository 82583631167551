.navbar{
  position: fixed;
  z-index: 1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

}

.nav-link:hover{
  color: #38b6ff;
}

.active-link {
  color: #38b6ff !important; 
  text-decoration: underline;
  font-weight:bolder; 
}

.aboutUsBgImage{
  margin-top: 5%;
  height: 80vh;
  background-image: url("./images/aboutUsBackgroundImage.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  min-height: 500px; /* Set the minimum height */
  display: flex;
  align-items: center; /* Vertically centers content */
  /* justify-content: center;  */
  padding: 50px;
}


.aboutUsBgImage > div{
  width: 50vh;
}


.aboutUsInfo {
  padding: 0 auto;
  margin-left: 10vw;
  height: 300px;
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.8); /* Translucent black background */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: white; /* Make the text white for readability */
  font-weight: bold;
  font-size:xx-large;
  padding: 20px;
  border-radius: 10px; /* Optional: Add rounded corners */
  max-width: 500px; /* Limit the width of the content */
}

div {
  margin: 10px 0;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}
